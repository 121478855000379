<template>
  <div>
    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-pen"></i> Filtros
        </h5>
      </div>

      <div class="w-100 p-3">
        <b-row>
          <b-col lg="2">
            <b-form-group label="Projeto">
              <b-form-input v-model="filters.id"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="6">
            <b-form-group label="Nome do cliente">
              <b-form-input v-model="filters.name"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group label="Nome do agente">
              <b-form-input v-model="filters.agent"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group label="Estado">
              <b-form-select @change="getCities" v-model="filters.province" :options="provinces"></b-form-select>
            </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group label="Cidade">
              <b-form-select :disabled="!cities.length" v-model="filters.city" :options="cities"></b-form-select>
            </b-form-group>
          </b-col>

          <!-- <b-col lg="4">
            <b-form-group label="Data">
              <b-form-input v-model="filters.data" type="date"></b-form-input>
            </b-form-group>
          </b-col> -->

          <b-col lg="4" class="align-btn">
            <b-button variant="info" @click="getAll(true)" class="mr-2">Pesquisar</b-button>
            <b-button variant="danger" @click="clearFilters">Limpar</b-button>
          </b-col>
        </b-row>
      </div>
    </b-row>

    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-file"></i> Projetos Cadastrados
        </h5>
      </div>

      <div class="w-100 p-3">
        <b-button variant="primary" :to="{ name: 'project-create' }">
          <i class="fas fa-plus"></i> Adicionar novo pré projeto
        </b-button>
      </div>

      <div class="w-100 p-3">
        <b-table
          responsive
          :busy="load"
          bordered
          :fields="columnsTableProjects"
          :items="clients"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <template #table-busy>
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Carregando...</strong>
            </div>
          </template>
          <template v-slot:cell(created_at)="data">
            {{ data.item.created_at | moment('DD/MM/YY') }}
          </template>
          <template v-slot:cell(actions)="data">
            <div>
              <b-dropdown variant="info" text="Ações" right toggle-class="text-decoration-none">
                <!-- <template #button-content>
                  <i class="fas fa-ellipsis-v"></i>
                </template> -->
                <b-dropdown-item :href="data.item.link" target="blank"><i class="fas fa-file"></i> Gerar PDF</b-dropdown-item>
                <b-dropdown-item :to="{ name: data.item.route, params:{ id: data.item.id }}">
                  <i class="fas fa-pen"></i> Editar projeto
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{ name: 'financing-create', params: { client_id: data.item.client_id } }
                ">
                  <i class="fas fa-dollar-sign"></i> Solicitar Financiamento
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <!-- <div class="d-flex justify-content-around">
              <b-button
              v-b-tooltip.hover
              size="sm"
              title="Acompanhar pré projeto"
              :to="{ name: 'project', params:{ id: data.item.id }}"
              variant="warning">
                <i class="fas fa-info-circle"></i>
              </b-button>
              <b-link :href="data.item.link" class="text-light" target="_blank">
                <b-button
                v-b-tooltip.hover
                variant="info"
                title="Visualizar PDF"
                size="sm"
                >
                  <i class="fas fa-file-pdf"></i>
                </b-button>
              </b-link>
              <b-button
              :to="{ name: 'pre-inspection', params:{ id: data.item.id }}"
              v-b-tooltip.hover
              variant="primary"
              title="Realizar pré vistoria"
              size="sm">
                <i class="fab fa-searchengin"></i>
              </b-button>
            </div> -->
          </template>
        </b-table>
      </div>
      <div class="w-100 py-3" v-if="totalRows > 15">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
    </b-row>
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import HelperService from '@/Services/HelperService';
import ProjectService from '@/Services/ProjectService';
import ClientService from '@/Services/ClientService';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';

export default {
  data() {
    return {
      columnsTableProjects: ProjectService.getFieldsTableProjects(),
      clients: [],
      totalRows: 0,
      currentPage: 1,
      load: true,
      perPage: 15,
      provinces: [],
      cities: [],
      filters: {
        id: '',
        name: '',
        agent: '',
        province: '',
        city: '',
        date: '',
      },
      payload: {
        client_id: '',
        title: '',
        description: '',
      },
    };
  },
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  validations: {
    payload: {
      client_id: { required },
      title: { required },
      description: { required },
    },
  },
  methods: {
    async getAll(hasFilter) {
      const filters = { ...this.filters };
      this.load = true;

      try {
        const { data } = hasFilter ? await ProjectService.getList(filters) : await ProjectService.getList();
        this.totalRows = data.length;
        this.clients = data.map((item) => {
          let agent = '';
          if (item.agent?.name) agent = item.agent.name;
          else if (item.user?.name) agent = item.user.name;
          else agent = 'N/A';
          return {
            id: item.id,
            client: item.client.name,
            client_id: item.client.id,
            agent,
            city: item.city ? item.city.name : item.client.addresses[0].city.name,
            province: item.province ? item.province.initial : item.client.addresses[0].city.province.initial,
            value_project: this.$options.filters.currency(item.price),
            created_at: item.created_at,
            link: `${process.env.VUE_APP_PDF_LINK}${item.id}`,
            route: item.custon ? 'project-custon' : 'project',
          };
        });
        console.log(this.clients);
      } catch (error) {
        console.log(error);
      }
      this.load = false;
    },

    clearFilters() {
      this.getAll(false);
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = '';
      });
    },

    async getClients() {
      try {
        const { data } = await ClientService.getList();
        this.clients = data.map((item) => ({
          text: item.name,
          value: item.id,
        }));
      } catch (error) {
        console.log(error);
      }
    },

    async getProvinces() {
      const { data } = await HelperService.getProvinces();
      data.forEach((item) => {
        this.provinces.push({
          text: item.name,
          value: item.id,
        });
      });
    },

    async getCities() {
      const params = {
        province_id: this.filters.province,
      };

      const { data } = await HelperService.getCities(params);
      this.cities = data.map((item) => ({
        text: item.name,
        value: item.id,
      }));
    },

    reset() {
      this.payload.client_id = '';
      this.payload.title = '';
      this.payload.description = '';
      this.$v.payload.$reset();
    },

    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.save();
    },

    async save() {
      this.$v.payload.$touch();
      if (!this.$v.payload.$invalid) {
        try {
          await ProjectService.save(this.payload);
          this.messageSuccess('Atividade cadastrada com sucesso');

          this.$nextTick(() => {
            this.$refs['create-activity'].hide();
          });

          this.getAll();
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  created() {
    this.getAll(false);
    this.getProvinces();
  },
};
</script>
